import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import axios from 'axios'
import VCalendar from 'v-calendar'
import VueGtag from "vue-gtag"

Vue.prototype.$http = axios

// TODO -
// 1. Add Google Analytics

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBBveY5v_ASb0RgISzdLvxwJRsNwHM4F84',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

Vue.use(VCalendar, {});

Vue.use(VueGtag, {
  config: { id: "G-QW5E3TS651" }
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
