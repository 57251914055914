<template>
  <div>
    <!--    START DATETIME PICKER-->
    <v-date-picker v-model="startDate" mode="dateTime">
      <template v-slot="{ inputValue, inputEvents }">
        <label for="from">From</label>
        <input
            class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
            :value="inputValue"
            v-on="inputEvents"
            id="from"
        />
      </template>
    </v-date-picker>
    <v-date-picker v-model="endDate" mode="dateTime">
      <template v-slot="{ inputValue, inputEvents }">
        <label for="to">To</label>
        <input
            class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
            :value="inputValue"
            v-on="inputEvents"
            id="to"
        />
      </template>
    </v-date-picker>
    <!--    END DATETIME PICKER-->

    <button @click="resetBounds()">Search in this area</button>
    <!--    {{incidents}}-->

    <gmap-map
        :center="{lat:37.75692146,lng:-122.4527422}"
        :zoom="13"
        ref="mapRef"
        style="width: 100%; height: 900px;">
      <gmap-info-window
          :options="infoWindowOptions"
          :position="infoWindowPosition"
          :opened="infoWindowOpened"
          @closeclick="handleInfoWindowClose"
      >
        <div class="info-window">
          <h2 v-text="activeIncident.incident_category"></h2>
          <h5 v-text="activeIncident.incident_subcategory"></h5>
          <p v-text="activeIncident.incident_description"></p>
          <p v-text="activeIncident.incident_datetime"></p>
          <p v-text="activeIncident.resolution"></p>
        </div>
      </gmap-info-window>
      <gmap-marker v-for="incident in incidents" :key="incident.row_id"
                   :position=getPosition(incident)
                   :clickable="true"
                   :draggable="false"
                   @click="handleMarkerClicked(incident)"
                   :icon=getIcon(incident)
      >a</gmap-marker>

    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "Map",
  data() {
    return {
      incidents: [],
      infoWindowOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        }
      },
      activeIncident: {

      },
      infoWindowOpened: false,
      startDate: new Date(),
      endDate: new Date()
    }
  },
  computed: {
    infoWindowPosition()
    {
      return {
        lat: parseFloat(this.activeIncident.latitude),
        lng: parseFloat(this.activeIncident.longitude),
      }
    }
  },
  created() {
    this.$gtag.pageview({
        page_path: '/',
      })

    // past 2 days
    this.endDate = new Date()
    this.startDate = new Date(this.startDate - 1000 * 60 * 60 * 24 * 2)
    let options = {
      headers: {
        'X-App-Token': 'XRwCcN6WQQHaUjUKbsZtfeJOa'
      }
    }
    let theUrl = `https://data.sfgov.org/resource/wg3w-h783.json?$where=incident_datetime between '${this.startDate.toISOString().replace('Z', '')}' and '${this.endDate.toISOString().replace('Z', '')}'&$limit=100`
    this.$http.get(theUrl, options)
        .then(response => this.incidents = response.data)
        .catch(error => console.error(error))
  },
  methods: {
    getPosition(incident) {
      // let theStr = `{lat:${incident.latitude},lng:${incident.longitude}}`
      // console.log(theStr)
      return {
        lat: parseFloat(incident.latitude),
        lng: parseFloat(incident.longitude)
      }
    },
    getIcon(incident) {
      let color = 'red'
      switch (incident.incident_category) {
        case 'Homicide':
          color = 'red'
          break
        case 'Larceny Theft':
          color = 'green'
          break
        case 'Assault':
          color = 'blue'
          break
        case 'Burglary':
          color = 'yellow'
          break
        case 'Rape':
          color = 'red'
          break
        case 'Robbery':
          color = 'orange'
          break
        case 'Motor Vehicle Theft':
          color = 'pink'
          break
      }
      return {
        'url': `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`
      }
    },
    handleMarkerClicked(incident) {
      this.activeIncident = incident
      this.infoWindowOpened = true
    },
    handleInfoWindowClose() {
      this.activeIncident = {}
      this.infoWindowOpened = false
    },
    resetBounds() {
      this.$gtag.event('click', {
        'event_category': 'buttons',
        'event_label': 'search in this map',
      })
      this.$refs.mapRef.$mapPromise.then((map) => {
        let bounds = map.getBounds()
        // console.log(map.getBounds())
        let options = {
          params: {
            $query: `SELECT * WHERE incident_datetime between '${this.startDate.toISOString().replace('Z', '')}' and '${this.endDate.toISOString().replace('Z', '')}' AND within_box(point, ${bounds.getNorthEast().lat()}, ${bounds.getSouthWest().lng()}, ${bounds.getSouthWest().lat()}, ${bounds.getNorthEast().lng()}) LIMIT 500`
          },
          headers: {
            'X-App-Token': 'XRwCcN6WQQHaUjUKbsZtfeJOa'
          }
        }
        // SELECT * WHERE incident_datetime between '2020-10-23T22:02:56.451' and '2020-10-25T22:02:56.451' AND within_box(point, 37.749393893001546, -122.46438904110757, 37.74175857568126, -122.4825315028843) LIMIT 1
        // let theUrl = `https://data.sfgov.org/resource/wg3w-h783.json?$where=incident_datetime between '${yesterday.toISOString().replace('Z', '')}' and '${current.toISOString().replace('Z', '')}'&$limit=50&$where=within_box(point, ${bounds.getNorthEast().lat()}, ${bounds.getSouthWest().lng()}, ${bounds.getSouthWest().lat()}, ${bounds.getNorthEast().lng()})`
        // console.log(theUrl)
        this.$http.get('https://data.sfgov.org/resource/wg3w-h783.json', options)
            .then(response => this.incidents = response.data)
            .catch(error => console.error(error))
      })
    }
  }
}
</script>

<style scoped>

</style>